import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { useFormik } from "formik";
import * as yup from "yup";
import i18n from "./i18n";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuItem from "@material-ui/core/MenuItem";
import { inject } from "mobx-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import firebase from "firebase/app";
import { Typography } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import InputMask from "react-input-mask";


const useStyles = makeStyles((theme) => ({
  dropDown: {
    minWidth: 150,
  },
  paper: {
    marginTop: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Render(props) {
  const { t } = props;
  const [user, setUser] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [successKey, setSuccessKey] = useState("");
  const userId = props.store.userStore.id;
  const db = firebase.firestore();
  var header = "";
  

 

  switch (props.userType) {
    case "NEW_DEPENDENT":
      break;
    case "EXISTING_DEPENDENT":
      break;
    default:
      header = t("user.updateUserProfileHeader");
      break;
  }

  const validationSchema = yup.object({
    firstName: yup.string().required(t("user.firstNameRequired")),
    lastName: yup.string().required(t("user.lastNameRequired")),
    addLine1: yup.string().required(t("user.addLine1Required")),
    city: yup.string().required(t("user.cityRequired")),
    state: yup.string().required(t("user.stateRequired")),
    maritalStatus: yup.string().required(t("user.maritalStatusRequired")),
    communicationLanguage: yup
      .string()
      .required(t("user.communicationLanguageRequired")),
    zip: yup
      .string(t("user.zipRequired"))
      .required(t("user.zipRequired"))
      .matches(/\d{5}$/, t("user.zipRequired")),
    ssn: yup
      .string(t("user.ssnRequired"))
      .required(t("user.ssnRequired"))
      .matches(/\d{9}$/, t("user.ssnRequired")),
    dob: yup
      .date()
      .required(t("user.dobValid"))
      .max(new Date(), t("user.dobValid")),
    phone: yup
      .string(t("user.phoneRequired"))
      .required(t("user.phoneRequired"))
      .matches(/\d{10}$/, t("user.phoneRequired")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      dob: user.dob,
      addLine1: user.addLine1,
      addLine2: user.addLine2,
      ssn: user.ssn,
      city: user.city,
      state: user.state,
      zip: user.zip,
      phone: user.phone,
      maritalStatus: user.maritalStatus,
      communicationLanguage: i18n.language,
    },

    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,

    onSubmit: async (values) => {
      setErrorKey("");
      setSuccessKey("");
      const updateUser = {};
      updateUser.id = userId;
      Object.assign(updateUser, values);
      updateUser.dob = updateUser.dob.toString();
      updateUser.profileComplete = true;

      try {
        await trackPromise(
          db.collection("users").doc(userId).update(updateUser)
        );
        props.store.userStore.setProfileComplete(true);
        setSuccessKey("user.userUpdatedSuccess");
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
        setErrorKey("genericError");
        window.scrollTo(0, 0);
      }
    },
  });

  const params = new URLSearchParams(props.location.search);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await trackPromise(
          db.collection("users").doc(userId).get()
        );
        return response.data();
      } catch (error) {
        setErrorKey("genericError");
      }
    };

    async function fetchData() {
      const response = await getUser();
      setUser(response);
    }
    fetchData();
  }, [userId]);

  const handleChange = (...args) => {
   
    formik.handleChange(...args);
    formik.handleBlur(...args);
    console.log(formik.values.phone);
  };


  const phoneChange = (...args) => {
    handleChange(...args);
 
  };


  

  const phoneInput = (props) => (

 <InputMask maskChar="_" mask={"999-999-9999"} {...props}  onChange={phoneChange} />
    
  );
  

  const classes = useStyles();

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const handleDateChange = (date) => {
    formik.setFieldValue("dob", !isValidDate(date) ? "" : date, true);
    formik.setFieldTouched("dob", true, true);
  };

  const handleDateBlur = (ev) => {
    const dobDate = new Date(ev.target.value);
    handleDateChange(dobDate);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant="h5">{header}</Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {errorKey !== "" ? (
              <Alert severity="error">{t(errorKey)} </Alert>
            ) : (
              <></>
            )}
            {successKey !== "" ? (
              <Alert severity="success">{t(successKey)} </Alert>
            ) : (
              <></>
            )}
<InputMask maskChar="_" mask={"999-999-9999"}   onChange={props.onChange} />  
            <TextField
              inputProps={{
                maxLength: 34,
                style: { textTransform: "uppercase" },
              }}
              autoFocus={true}
              required
              margin="normal"
              fullWidth
              id="firstName"
              variant="outlined"
              name="firstName"
              label={t("user.firstName")}
              value={formik.values.firstName || ""}
              onChange={handleChange}
              onBlur={handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />

            <TextField
              margin="normal"
              fullWidth
              id="middleName"
              variant="outlined"
              name="middleName"
              label={t("user.middleName")}
              value={formik.values.middleName || ""}
              onChange={handleChange}
              inputProps={{
                maxLength: 34,
                style: { textTransform: "uppercase" },
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.middleName && Boolean(formik.errors.middleName)
              }
              helperText={formik.touched.middleName && formik.errors.middleName}
            />

            <TextField
              required
              margin="normal"
              fullWidth
              id="lastName"
              name="lastName"
              variant="outlined"
              label={t("user.lastName")}
              value={formik.values.lastName || ""}
              onChange={handleChange}
              inputProps={{
                maxLength: 34,
                style: { textTransform: "uppercase" },
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
    
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                margin="normal"
                label={t("user.dob")}
                disableToolbar
                disableFuture
                value={formik.values.dob || null}
                inputVariant="outlined"
                onBlur={handleDateBlur}
                onChange={(date) => handleDateChange(date)}
                format="MM/dd/yyyy"
                openTo="year"
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </MuiPickersUtilsProvider>

            
            <TextField
              required
              margin="normal"
              fullWidth
              type="tel"
              id="phone"
              variant="outlined"
              name="phone"
              label={t("user.phone")}
              value={formik.values.phone || null}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                inputComponent: phoneInput,
              }}
            ></TextField>


            <TextField
              inputProps={{
                maxLength: 9,
              }}
              required
              margin="normal"
              fullWidth
              id="ssn"
              variant="outlined"
              name="ssn"
              label={t("user.ssn")}
              value={formik.values.ssn || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ssn && Boolean(formik.errors.ssn)}
              helperText={formik.touched.ssn && formik.errors.ssn}
            />

            <TextField
              margin="normal"
              id="maritalStatus"
              name="maritalStatus"
              className={classes.dropDown}
              required
              select
              label={t("user.maritalStatus")}
              value={formik.values.maritalStatus || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.maritalStatus &&
                Boolean(formik.errors.maritalStatus)
              }
              helperText={
                formik.touched.maritalStatus && formik.errors.maritalStatus
              }
              variant="outlined"
            >
              <MenuItem key="SI" value="SI">
                {t("user.maritalStatusAbbr.SI")}
              </MenuItem>
              <MenuItem key="MA" value="MA">
                {t("user.maritalStatusAbbr.MA")}
              </MenuItem>
              <MenuItem key="DI" value="DI">
                {t("user.maritalStatusAbbr.DI")}
              </MenuItem>
              <MenuItem key="WI" value="WI">
                {t("user.maritalStatusAbbr.WI")}
              </MenuItem>
            </TextField>

            <TextField
              required
              margin="normal"
              fullWidth
              id="addLine1"
              variant="outlined"
              name="addLine1"
              label={t("user.addLine1")}
              value={formik.values.addLine1 || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 40,
                style: { textTransform: "uppercase" },
              }}
              error={formik.touched.addLine1 && Boolean(formik.errors.addLine1)}
              helperText={formik.touched.addLine1 && formik.errors.addLine1}
            />

            <TextField
              margin="normal"
              fullWidth
              id="addLine2"
              variant="outlined"
              name="addLine2"
              label={t("user.addLine2")}
              value={formik.values.addLine2 || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 40,
                style: { textTransform: "uppercase" },
              }}
              error={formik.touched.addLine2 && Boolean(formik.errors.addLine2)}
              helperText={formik.touched.addLine2 && formik.errors.addLine2}
            />

            <TextField
              inputProps={{
                maxLength: 40,
                style: { textTransform: "uppercase" },
              }}
              required
              margin="normal"
              fullWidth
              id="city"
              variant="outlined"
              name="city"
              label={t("user.city")}
              value={formik.values.city || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />

            <TextField
              margin="normal"
              id="state"
              name="state"
              className={classes.dropDown}
              required
              select
              label={t("user.state")}
              value={formik.values.state || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              variant="outlined"
            >
              <MenuItem key="AL" value="AL">
                {t("user.stateAbbr.AL")}
              </MenuItem>
              <MenuItem key="AK" value="AK">
                {t("user.stateAbbr.AK")}
              </MenuItem>
              <MenuItem key="AZ" value="AZ">
                {t("user.stateAbbr.AZ")}
              </MenuItem>
              <MenuItem key="AR" value="AR">
                {t("user.stateAbbr.AR")}
              </MenuItem>
              <MenuItem key="CA" value="CA">
                {t("user.stateAbbr.CA")}
              </MenuItem>
              <MenuItem key="CO" value="CO">
                {t("user.stateAbbr.CO")}
              </MenuItem>
              <MenuItem key="CT" value="CT">
                {t("user.stateAbbr.CT")}
              </MenuItem>
              <MenuItem key="DE" value="DE">
                {t("user.stateAbbr.DE")}
              </MenuItem>
              <MenuItem key="DC" value="DC">
                {t("user.stateAbbr.DC")}
              </MenuItem>
              <MenuItem key="FL" value="FL">
                {t("user.stateAbbr.FL")}
              </MenuItem>
              <MenuItem key="GA" value="GA">
                {t("user.stateAbbr.GA")}
              </MenuItem>
              <MenuItem key="HI" value="HI">
                {t("user.stateAbbr.HI")}
              </MenuItem>
              <MenuItem key="ID" value="ID">
                {t("user.stateAbbr.ID")}
              </MenuItem>
              <MenuItem key="IL" value="IL">
                {t("user.stateAbbr.IL")}
              </MenuItem>
              <MenuItem key="IN" value="IN">
                {t("user.stateAbbr.IN")}
              </MenuItem>
              <MenuItem key="IA" value="IA">
                {t("user.stateAbbr.IA")}
              </MenuItem>
              <MenuItem key="KS" value="KS">
                {t("user.stateAbbr.KS")}
              </MenuItem>
              <MenuItem key="KY" value="KY">
                {t("user.stateAbbr.KY")}
              </MenuItem>
              <MenuItem key="LA" value="LA">
                {t("user.stateAbbr.LA")}
              </MenuItem>
              <MenuItem key="ME" value="ME">
                {t("user.stateAbbr.ME")}
              </MenuItem>
              <MenuItem key="MD" value="MD">
                {t("user.stateAbbr.MD")}
              </MenuItem>
              <MenuItem key="MA" value="MA">
                {t("user.stateAbbr.MA")}
              </MenuItem>
              <MenuItem key="MA">{t("user.stateAbbr.MI")}</MenuItem>
              <MenuItem key="MN" value="MN">
                {t("user.stateAbbr.MN")}
              </MenuItem>
              <MenuItem key="MN" value="MS">
                {t("user.stateAbbr.MS")}
              </MenuItem>
              <MenuItem key="MN" value="MO">
                {t("user.stateAbbr.MO")}
              </MenuItem>
              <MenuItem key="MN" value="MT">
                {t("user.stateAbbr.MT")}
              </MenuItem>
              <MenuItem key="NE" value="NE">
                {t("user.stateAbbr.NE")}
              </MenuItem>
              <MenuItem key="NV" value="NV">
                {t("user.stateAbbr.NV")}
              </MenuItem>
              <MenuItem key="NH" value="NH">
                {t("user.stateAbbr.NH")}
              </MenuItem>
              <MenuItem key="NJ" value="NJ">
                {t("user.stateAbbr.NJ")}
              </MenuItem>
              <MenuItem key="NM" value="NM">
                {t("user.stateAbbr.NM")}
              </MenuItem>
              <MenuItem key="NH" value="NH">
                {t("user.stateAbbr.NH")}
              </MenuItem>
              <MenuItem key="NJ" value="NJ">
                {t("user.stateAbbr.NJ")}
              </MenuItem>
              <MenuItem key="NM" value="NM">
                {t("user.stateAbbr.NM")}
              </MenuItem>
              <MenuItem key="NY" value="NY">
                {t("user.stateAbbr.NY")}
              </MenuItem>
              <MenuItem key="NC" value="NC">
                {t("user.stateAbbr.NC")}
              </MenuItem>
              <MenuItem key="ND" alue="ND">
                {t("user.stateAbbr.ND")}
              </MenuItem>
              <MenuItem key="OH" value="OH">
                {t("user.stateAbbr.OH")}
              </MenuItem>
              <MenuItem key="OK" value="OK">
                {t("user.stateAbbr.OK")}
              </MenuItem>
              <MenuItem key="OR" value="OR">
                {t("user.stateAbbr.OR")}
              </MenuItem>
              <MenuItem key="PA" value="PA">
                {t("user.stateAbbr.PA")}
              </MenuItem>
              <MenuItem key="RI" value="RI">
                {t("user.stateAbbr.RI")}
              </MenuItem>
              <MenuItem key="SC" value="SC">
                {t("user.stateAbbr.SC")}
              </MenuItem>
              <MenuItem key="SD" value="SD">
                {t("user.stateAbbr.SD")}
              </MenuItem>
              <MenuItem key="TN" value="TN">
                {t("user.stateAbbr.TN")}
              </MenuItem>
              <MenuItem key="TX" value="TX">
                {t("user.stateAbbr.TX")}
              </MenuItem>
              <MenuItem key="WA" value="WA">
                {t("user.stateAbbr.WA")}
              </MenuItem>
              <MenuItem key="WV" value="WV">
                {t("user.stateAbbr.WV")}
              </MenuItem>
              <MenuItem key="WI" value="WI">
                {t("user.stateAbbr.WI")}
              </MenuItem>
              <MenuItem key="WY" value="WY">
                {t("user.stateAbbr.WY")}
              </MenuItem>
            </TextField>

            <TextField
              inputProps={{
                maxLength: 5,
              }}
              type="num"
              required
              margin="normal"
              fullWidth
              id="zip"
              variant="outlined"
              name="zip"
              label={t("user.zip")}
              value={formik.values.zip || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
            />

            <TextField
              margin="normal"
              id="communicationLanguage"
              name="communicationLanguage"
              className={classes.dropDown}
              required
              select
              label={t("user.communicationLanguage")}
              value={formik.values.communicationLanguage || ""}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.communicationLanguage &&
                Boolean(formik.errors.communicationLanguage)
              }
              helperText={
                formik.touched.communicationLanguage &&
                formik.errors.communicationLanguage
              }
              variant="outlined"
            >
              <MenuItem key="en" value="en">
                {t("user.communicationLanguageAbbr.EN")}
              </MenuItem>
              <MenuItem key="es" value="es">
                {t("user.communicationLanguageAbbr.ES")}
              </MenuItem>
            </TextField>

            <Button
              margin="normal"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {t("submit")}
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}

export default withRouter(withTranslation()(inject("store")(Render)));
