import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { usePromiseTracker } from "react-promise-tracker";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opactity: "0.5",
  },
}));

function Render(props) {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <React.Fragment>
        <div className={classes.root}>
          <CircularProgress></CircularProgress>;
        </div>
      </React.Fragment>
    )
  );
}

export default Render;
