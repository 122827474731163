import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withAuthentication from "../authentication/authentication-hoc";
import firebase from "firebase/app";
import logo from "../images/logo.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NavHashLink } from "react-router-hash-link";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "white",
    boxShadow: "none",
  },
  toolbar: {},
  menuButton: {
    flexGrow: 1,
  },
  title: {
    fontWeight: 600,
  },
}));

function Render(props) {
  const trigger = useScrollTrigger();
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { t } = props;

  const signOut = async () => {
    await props.signOut();
    props.history.push("/");
  };

  const changeLanguage = (code) => (e) => {
    firebase.auth().languageCode = code;
    i18n.changeLanguage(code);
  };

  const lang = localStorage.getItem("i18nextLng");
  const authenticated = firebase.auth().currentUser;
  const pathName = props.store.routing.location.pathname;
  const staging = process.env.NODE_ENV === "staging";

  const renderSignin = () => {
    return (
      <>
        {staging ? (
          <Button component={Link} to="/signin" color="inherit">
            {t("navBar.signIn")}
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderMenu = () => {
    return (
      <>
        {authenticated && props.store.userStore.profileComplete ? (
          <Button component={Link} to="/mytaxes" color="inherit">
            {t("navBar.myTaxes")}
          </Button>
        ) : (
          <></>
        )}

        {authenticated && props.store.userStore.profileComplete ? (
          <Button component={Link} to="/myprofile" color="inherit">
            {t("navBar.myProfile")}
          </Button>
        ) : (
          <></>
        )}

        {pathName.toUpperCase() !== "/home".toUpperCase() ? (
          <Button component={Link} to="/home" color="inherit">
            {t("navBar.home")}
          </Button>
        ) : null}

        {!desktop || pathName.toUpperCase() !== "/home".toUpperCase() ? (
          <>
            <Button component={NavHashLink} to="/#pricing" color="inherit">
              {t("navBar.pricing")}
            </Button>

            <Button component={NavHashLink} to="/#location" color="inherit">
              {t("navBar.location")}
            </Button>

            <Button component={NavHashLink} to="/#location" color="inherit">
              {t("navBar.appointment")}
            </Button>
          </>
        ) : null}

        {lang.includes("en") ? (
          <Button onClick={changeLanguage("es")} color="inherit">
            {t("navBar.language")}
          </Button>
        ) : (
          <Button onClick={changeLanguage("en")} color="inherit">
            {t("navBar.language")}
          </Button>
        )}

        {authenticated ? (
          <Button onClick={signOut} color="inherit">
            {t("navBar.signOut")}
          </Button>
        ) : (
          <>{renderSignin()}</>
        )}
      </>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="fixed" color="transparent">
          <Toolbar>
            <a href="/">
              <img src={logo} width="60" height="45" alt="Logo"></img>
            </a>
            <Typography
              variant="h5"
              component={Link}
              to="/"
              className={classes.title}
              style={{ textDecoration: "none", color: "unset" }}
            >
              {t("navBar.title")}
            </Typography>

            {desktop ? (
              <React.Fragment>
                <div className={classes.menuButton}></div>
                {renderMenu()}
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Toolbar>

          {!desktop ? (
            <React.Fragment>
              <Toolbar className={classes.toolbar}>{renderMenu()}</Toolbar>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </AppBar>
      </div>
    </>
  );
}

export default withTranslation()(
  withRouter(inject("store")(withAuthentication(Render)))
);
