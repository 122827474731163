import Nav from "./nav/nav-bar";
import SignIn from "./authentication/signIn";
import SignUp from "./authentication/signUp";
import SendPasswordReset from "./authentication/sendPasswordReset";
import ResetPassword from "./authentication/resetPassword";
import Home from "./home";
import User from "./user";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from "./privateRouter";
import Progress from "./progress";
import Footer from "./footer";

function App() {
  return (
    <React.Fragment>
      <Nav />
      <Progress />
      <Switch>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/auth/action">
          <ResetPassword />
        </Route>
        <Route path="/sendpasswordreset">
          <SendPasswordReset />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <PrivateRoute component={User} path="/myprofile" exact />
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/location">
          <Redirect to="/#location" />
        </Route>
        <Route exact path="/appointment">
          <Redirect to="/#appointment" />
        </Route>
      </Switch>
      <Footer />
    </React.Fragment>
  );
}
/*
Home
User Profile
Dependents
My account
inprogress
Accepted
Fianlized
DocuSign
EmailAdmin
*/
export default App;
