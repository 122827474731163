import { types } from "mobx-state-tree";

export const UserStore = types
  .model("Store", {
    id: types.string,
    authenticated: types.boolean,
    profileComplete: types.boolean,
  })
  .actions((self) => {
    return {
      setId(id) {
        self.id = id;
      },
      setAuthenticated(authenticated) {
        self.authenticated = authenticated;
      },
      setProfileComplete(profileComplete) {
        self.profileComplete = profileComplete;
      },
    };
  });
