import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject } from "mobx-react";
import User from "./user";
import firebase from "firebase/app";

const Render = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        firebase.auth().currentUser ? (
          rest.store.profileComplete ? (
            <Component {...props} />
          ) : (
            <User {...[props]} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
            }}
          />
        )
      }
    />
  );
};

export default inject("store")(Render);
