import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { UserStore } from "./userStore";
import { Provider } from "mobx-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import Progress from "./progress";
import createBrowserHistory from "history/createBrowserHistory";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { BrowserRouter as Router } from "react-router-dom";

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import app from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSENGER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};




// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const userStore = UserStore.create({
  id: "",
  authenticated: firebase.user != null,
  profileComplete: false,
});

const history = syncHistoryWithStore(browserHistory, routingStore);

const stores = {
  // Key can be whatever you want
  routing: routingStore,
  userStore,
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Progress />}>
      <I18nextProvider i18n={i18n}>
        <Provider store={stores}>
          <Router history={history}>
            {" "}
            <App />
          </Router>
        </Provider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
