import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import withAuthentication from "./authentication-hoc";
import { Typography } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Render(props) {
  const { t } = props;
  const [errorKey, setErrorKey] = useState("");
  const [successKey, setSuccessKey] = useState("");
  const params = new URLSearchParams(props.location.search);

  const emailValidation =
    props.authenticationType === "signIn" ||
    props.authenticationType === "signUp" ||
    props.authenticationType === "sendPasswordReset"
      ? {
          email: yup
            .string(t("authentication.enterValidEmail"))
            .trim()
            .email(t("authentication.enterValidEmail"))
            .required(t("authentication.enterValidEmail")),
        }
      : {};
  const passwordValidation =
    props.authenticationType === "signIn" ||
    props.authenticationType === "signUp" ||
    props.authenticationType === "resetPassword"
      ? {
          password: yup
            .string()
            .required(t("authentication.passwordRequired"))
            .matches(
              /(?=^.{8,}$)(?=.*[!@#$%^&*]+)(?![.\n]).*$/,
              t("authentication.passwordValidation")
            ),
        }
      : {};

  const validationSchema = yup.object({
    ...emailValidation,
    ...passwordValidation,
  });

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,

    onSubmit: async (values) => {
      setSuccessKey("");
      setErrorKey("");
      try {
        switch (props.authenticationType) {
          case "signUp":
            await trackPromise(props.signUp(values.email, values.password));
            props.history.push("/myprofile");
            break;
          case "resetPassword":
            const code = params.get("oobCode");
            await trackPromise(props.resetPassword(code, values.password));
            setSuccessKey("authentication.resetPasswordSuccess");
            break;
          case "sendPasswordReset":
            await trackPromise(props.sendPasswordReset(values.email));
            setSuccessKey("authentication.sendPasswordResetSuccess");
            break;
          default:
            await trackPromise(props.signIn(values.email, values.password));
            props.history.push("/myprofile");
            break;
        }
      } catch (error) {
        console.log(error);
        switch (error.code) {
          case "auth/wrong-password":
            setErrorKey("authentication.invalidPassword");
            break;
          case "auth/email-already-in-use":
            setErrorKey("authentication.emailExists");
            break;
          case "auth/user-not-found":
            setErrorKey("authentication.emailDoesNotExists");
            break;
          case "auth/invalid-action-code":
            setErrorKey("authentication.passwordResetCodeBad");
            break;
          default:
            setErrorKey("genericError");
            break;
        }
      }
    },
  });

  const handleChange = (...args) => {
    formik.handleChange(...args);
    formik.handleBlur(...args);
  };

  var header = "";
  switch (props.authenticationType) {
    case "signUp":
      header = t("authentication.signUp");
      break;
    case "resetPassword":
      header = t("authentication.resetPassword");
      break;
    case "sendPasswordReset":
      header = t("authentication.sendPasswordReset");
      break;
    default:
      header = t("authentication.signIn");
      break;
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant="h5">{header}</Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {errorKey !== "" ? (
              <Alert severity="error">{t(errorKey)} </Alert>
            ) : (
              <></>
            )}
            {successKey !== "" ? (
              <Alert severity="success">{t(successKey)} </Alert>
            ) : (
              <></>
            )}
            {props.authenticationType === "signIn" ||
            props.authenticationType === "signUp" ||
            props.authenticationType === "sendPasswordReset" ? (
              <TextField
                autoFocus={true}
                required
                margin="normal"
                fullWidth
                id="email"
                variant="outlined"
                name="email"
                label={t("authentication.email")}
                value={formik.values.email}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            ) : (
              <></>
            )}
            {props.authenticationType === "signIn" ||
            props.authenticationType === "signUp" ||
            props.authenticationType === "resetPassword" ? (
              <TextField
                required
                fullWidth
                margin="normal"
                id="password"
                variant="outlined"
                name="password"
                label={t("authentication.password")}
                type="password"
                value={formik.values.password}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            ) : (
              <></>
            )}
            <Button
              type="submit"
              //disabled={!formik.isValid}
              margin="normal"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("submit")}
            </Button>
          </form>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Link href="/sendpasswordreset" variant="body2">
                {t("authentication.forgotPasswordLink")}
              </Link>
            </Grid>
            <Grid item xs={12} align="center">
              <Link href="/signUp" variant="body2">
                {t("authentication.signUpLink")}
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default withRouter(withTranslation()(withAuthentication(Render)));
