import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
export default function Render(props) {
  const useStyles = makeStyles((theme) => ({
    footer: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
    },
    space: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();

  return (
    <Toolbar className={classes.footer}>
      <div className={classes.space}></div>
      <Typography variant="caption" display="block" gutterBottom>
        © LaraTax.com
      </Typography>
      <div className={classes.space}></div>
    </Toolbar>
  );
}
