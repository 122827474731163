import React from "react";
import firebase from "firebase/app";
import { inject } from "mobx-react";

function withAuthentication(WrappedComponent) {
  const MyComponent = inject("store")((props) => {
    const db = firebase.firestore();
    const signOut = async () => {
      await firebase.auth().signOut();
      props.store.userStore.setAuthenticated(false);
    };

    const sendPasswordReset = async (email) => {
      await firebase.auth().sendPasswordResetEmail(email);
    };

    const resetPassword = async (code, password) => {
      await firebase.auth().confirmPasswordReset(code, password);
    };

    const signUp = async (email, password) => {
      const user = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      props.store.userStore.setAuthenticated(true);
      props.store.userStore.setId(user.user.uid);

      // Add a new document in collection "users"
      await db.collection("users").doc(user.user.uid).set({
        id: user.user.uid,
        profileComplete: false,
        email: email,
      });
    };

    const signIn = async (email, password) => {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      props.store.userStore.setAuthenticated(true);
      props.store.userStore.setId(user.user.uid);

      const response = await db.collection("users").doc(user.user.uid).get();
      if (response.data().profileComplete)
        props.store.userStore.setProfileComplete(true);
    };
    return (
      <WrappedComponent
        signIn={signIn}
        signOut={signOut}
        signUp={signUp}
        sendPasswordReset={sendPasswordReset}
        resetPassword={resetPassword}
        {...props}
      />
    );
  });

  return MyComponent;
}

export default withAuthentication;
