import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import vallejo from "./images/vallejo.png";
import { withTranslation } from "react-i18next";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import { openPopupWidget } from "react-calendly";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(14),
  },
  card: {
    margin: theme.spacing(),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    marginTop: "30",
  },
  paper: {
    padding: theme.spacing(2),
    height: theme.spacing(20),
    width: theme.spacing(30),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const bookAppointment = (url) => {
  openPopupWidget({ url });
};

function Render(props) {
  const classes = useStyles();
  const { t } = props;
  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="stretch" spacing={3}>
        <Grid item lg={3} component={Card} className={classes.card}>
          <CardHeader
            align="center"
            style={{ backgroundColor: "#e6e6e6" }}
            title={t("home.introductionHeader")}
          />

          <CardContent>
            <Typography variant="body1" color="textSecondary">
              {t("home.introduction")}
            </Typography>
          </CardContent>
          <CardMedia
            className={classes.media}
            image={vallejo}
            title="Jose Lara"
          />
        </Grid>

        <Grid
          id="pricing"
          item
          lg={3}
          component={Card}
          className={classes.card}
        >
          <CardHeader
            align="center"
            style={{ backgroundColor: "#e6e6e6" }}
            title={t("home.pricingHeader")}
          />

          <Card>
            <CardContent>
              <Typography align="center" variant="h5" color="textSecondary">
                {t("home.individualTaxesHeader")}
              </Typography>
              <Typography align="center" variant="h3">
                {t("home.individualTaxesPrice")}
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.individualTaxesIncludes")}
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography align="center" variant="h5" color="textSecondary">
                {t("home.smallBusinessTaxesHeader")}
              </Typography>
              <Typography align="center" variant="h3">
                {t("home.smallBusinessTaxesPrice")}
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.smallBusinessIncludes")}
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography align="center" variant="h5" color="textSecondary">
                {t("home.otherForms")}
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.stocks")}
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.itin")}
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.rental")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          id="location"
          item
          sm={3}
          component={Card}
          className={classes.card}
        >
          <CardHeader
            align="center"
            style={{ backgroundColor: "#e6e6e6" }}
            title={t("home.locationHeader")}
          />
          <Card>
            <CardContent>
              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.dropOff")}
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography align="center" variant="h5" color="textSecondary">
                {t("home.vallejoOffice")}
              </Typography>

              <address>
                <Typography
                  align="center"
                  variant="body1"
                  color="textSecondary"
                >
                  1020 Tenesse Street Vallejo CA Vallejo, CA 94590
                </Typography>
              </address>

              <Link href="tel:707-647-2226">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PhoneIcon size="small" display="inline"></PhoneIcon>
                  <Typography
                    align="center"
                    variant="body1"
                    color="textSecondary"
                  >
                    {" "}
                    (707) 647-2226
                  </Typography>
                </div>
              </Link>

              <Typography align="center" variant="body1" color="textSecondary">
                {t("home.vallejoTimes")}
              </Typography>

              <Link href="mailto: customerservice@laratax.com">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <EmailIcon fontSize="small" />

                  <Typography
                    align="center"
                    variant="body1"
                    color="textSecondary"
                  >
                    {t("home.emailUs")}
                  </Typography>
                </div>
              </Link>
              <Button
                color="primary"
                margin="normal"
                variant="contained"
                fullWidth
                onClick={() => {
                  bookAppointment(t("home.vallejoAppointmentLink"));
                }}
              >
                {t("home.bookAppointment")}
              </Button>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Button
                color="primary"
                margin="normal"
                variant="contained"
                fullWidth
                onClick={() => {
                  bookAppointment(
                      t("home.virtualAppointmentLink")
                  );
                }}
              >
                {" "}
                {t("home.bookVirtualAppointment")}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withTranslation()(Render);
